import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import Application from "../components/Application"
import Image from "../images/hero.png"
import styled from "styled-components"
import ProductPageComponent from "../components/ProductPageComponent"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import DiskImageMain1 from "../images/KODIAK-k11-17-D-front-WEB-Large.jpg"

import DiskImage1 from "../images/k19-white-bg-image-disk.jpg"
import DiskImage2 from "../images/KODIAK-K16-26-D-front-side-THUMB.jpg"
import DiskImage3 from "../images/KODIAK-K16-26-D-front-side-back-THUMB.jpg"
import DiskImage4 from "../images/KODIAK-K16-26-D-front-side-back-twist-THUMB.jpg"

import CylindricalImage1 from "../images/k19-white-bg-cylidrical.jpg"
import CylindricalImage2 from "../images/KODIAK-K16-26-C-front-side-THUMB.jpg"
import CylindricalImage3 from "../images/KODIAK-K16-26-C-front-side-back-THUMB.jpg"
import CylindricalImage4 from "../images/KODIAK-K16-26-C-front-side-back-twist-THUMB.jpg"

import OrbitalImage1 from "../images/k19-white-bg-image-orbital.jpg"
import OrbitalImage2 from "../images/KODIAK-K16-28-O-front-side-THUMB.jpg"
import OrbitalImage3 from "../images/KODIAK-K16-28-O-front-side-back-THUMB.jpg"
import OrbitalImage4 from "../images/KODIAK-K16-28-O-front-side-back-twist-THUMB.jpg"

import DiskImage1Large from "../images/k19-white-bg-image-disk.jpg"
import DiskImage2Large from "../images/KODIAK-K16-26-D-front-side-LARGE.jpg"
import DiskImage3Large from "../images/KODIAK-K16-26-D-front-side-back-LARGE.jpg"
import DiskImage4Large from "../images/KODIAK-K16-26-D-front-side-back-twist-LARGE.jpg"

import CylindricalImage1Large from "../images/k19-white-bg-cylidrical.jpg"
import CylindricalImage2Large from "../images/KODIAK-K16-26-C-front-side-LARGE.jpg"
import CylindricalImage3Large from "../images/KODIAK-K16-26-C-front-side-back-LARGE.jpg"
import CylindricalImage4Large from "../images/KODIAK-K16-26-C-front-side-back-twist-LARGE.jpg"

import OrbitalImage1Large from "../images/k19-white-bg-image-orbital.jpg"
import OrbitalImage2Large from "../images/KODIAK-K16-28-O-front-side-LARGE.jpg"
import OrbitalImage3Large from "../images/KODIAK-K16-28-O-front-side-back-LARGE.jpg"
import OrbitalImage4Large from "../images/KODIAK-K16-28-O-front-side-back-twist-LARGE.jpg"

import CylindricalImageThumb from "../images/cylindrical-3d-small.jpg"
import DiskImageThumb from "../images/disk-thumb.jpg"
import OrbitalImageThumb from "../images/orbital-thumb.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"
import CylindricalImage from "../images/cylindrica-deck-imagel-400x.png"
import DiskImage from "../images/disk-deck-400.png"

const K11PageMain = styled.main`
  padding: 2rem;
`

const K19SeriesPage = props => {
  return (
    <>
      <SEO title="Floor Scrubbers: K19 Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <Helmet>
        <title>
          Floor Scrubbers: K19 Walk-Behind Industrial Cleaning Equipment -
          Kodiak Cleaning Equipment
        </title>
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading
          pageHeading="K19 Floor Scrubbers"

          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        {/* <h1 style={{ color: "red", textAlign: "center" }}>
          **Coming Fall 2020**
        </h1> */}
        <K11PageMain>
          <ProductPageComponent
            chooseTitle="Choose Your Scrub Deck"
            descriptionTitle1="Solution Tank Capacity:"
            descriptionTitle2="Run Time:"
            mainDiskImage={DiskImage1Large}
            mainCylindricalImage={CylindricalImage1Large}
            mainOrbitalImage={OrbitalImage1Large}
            thumbDiskImage1={DiskImage1}
            thumbDiskImage2={DiskImage2}
            thumbDiskImage3={DiskImage3}
            thumbDiskImage4={DiskImage4}
            thumbCylindricalImage1={CylindricalImage1}
            thumbCylindricalImage2={CylindricalImage2}
            thumbOrbitalImage1={OrbitalImage1}
            thumbOrbitalImage2={OrbitalImage2}
            thumbOrbitalImage3={OrbitalImage3}
            thumbOrbitalImage4={OrbitalImage4}
            DiskImage1Large={DiskImage1Large}
            DiskImage2Large={DiskImage2Large}
            DiskImage3Large={DiskImage3Large}
            DiskImage4Large={DiskImage4Large}
            CylindricalImage1Large={CylindricalImage1Large}
            CylindricalImage2Large={CylindricalImage2Large}
            OrbitalImage1Large={OrbitalImage1Large}
            OrbitalImage2Large={OrbitalImage2Large}
            OrbitalImage3Large={OrbitalImage3Large}
            OrbitalImage4Large={OrbitalImage4Large}
            diskTitle="K19 Disk"
            subTitle="Walk-Behind Small Floor Scrubber"
            diskDescription="Cleaning small areas with a budget in mind has just gotten easier with the K19 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            diskCleaningPath="24 - 28in (61 - 71cm)"
            diskGallons="Sol: 19gal (72L) Rec: 19gal (72L)"
            diskRunTime=" Up to 2.5 hours"
            cylindricalTitle="K19 Cylindrical"
            cylindricalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K19 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            cylindricalCleaningPath="24 - 28in (61 - 71cm)"
            cylindricalGallons="Sol: 19gal (72L) Rec: 19gal (72L)"
            cylindricalRunTime=" Up to 2.5 hours"
            orbitalTitle="K19 Orbital"
            orbitalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K19 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            orbitalCleaningPath="24 - 28in (61 - 71cm)"
            orbitalGallons="Sol: 19gal (72L) Rec: 19gal (72L)"
            orbitalRunTime=" Up to 2.5 hours"
            DeckImageThumb1={DiskImageThumb}
            DeckImageThumb2={CylindricalImageThumb}
            DeckImageThumb3={OrbitalImageThumb}
            DeckImage1Description="Disk"
            DeckImage2Description="Cylindrical"
            DeckImage3Description="Orbital"
            DeckImageLarge1={DiskImage}
            DeckImageLarge2={CylindricalImage}
            DeckImageLarge3={OrbitalImage}
            // deckTitle="Why Disk"
            deckDescription1="Wavy / Irregular Floors"
            deckDescription2="Lowest Maintenance Cost"
            deckDescription3="Allows Pads"
            deckTitle2="Why Cylindrical"
            deckDescription4="Scrub & Sweep Simultaneously"
            deckDescription5="Tile & Grout Cleaning"
            deckDescription6="Rubber Surfaces & Track Fields"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../kodiak-walk-behind-sm.pdf"
            techPdf="../kodiak-k19-specsheet.pdf"
            partsPdf="https://www.dropbox.com/s/zfg0vl9qagw72ug/K-K19-P-EN.pdf?dl=0"
            operatorPdf="https://www.dropbox.com/s/l8c0slaarlskkb6/K-K19-OP-EN.pdf?dl=0"
            hoodPdf="../kodiak-underthehood.pdf"
            mobileDiskImage={DiskImageThumb}
            mobileCylindricalImage={CylindricalImageThumb}
            mobileOrbitalImage={OrbitalImageThumb}
            mobileDiskTitle="Disk"
            mobileCylindricalTitle="Cylindrical"
            mobileOrbitalTitle="Orbital"
            PmPdf="../K19-Series-PM.pdf"
            mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
          />
        </K11PageMain>
      </MainLayout>
    </>
  )
}

export default K19SeriesPage
